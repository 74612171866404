import React from 'react';
import styles from './v_image_text_uploads_container.module.css';
import VImageTextUploadContainer from '../projects/containers/vac/v_image_text_upload_container';
import { VImageTextUploadsContainerProps } from '../projects/containers/image_text_upload_container';

const VImageTextUploadsContainer = ({ imageTextUploadsContainerProps }: VImageTextUploadsContainerProps) => {
  return (
    <div className={styles.container}>
      {
        imageTextUploadsContainerProps.map((VImageTextUploadContainerProps, i) =>
          <div key={i} className={styles.item}>
            <VImageTextUploadContainer {...VImageTextUploadContainerProps} />
          </div>
        )
      }
    </div>
  )
};

export default VImageTextUploadsContainer;
