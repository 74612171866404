import React, { forwardRef, useState } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import VStartAtCalendarDate from './vac/v_start_at_calendar_date';
import VStartAtSelectDate from './vac/v_start_at_select_date';
import { selectOption, SelectProps } from '../selects/select';
import { SingleValue } from 'react-select';

export type StartAtDateProps = {
  type: number,
  dateString: string,
  onChangeDate: (date: string) => void,
};

export interface FancyButtonProps {
  onClick?: () => void,
  value?: string;
}

export type VStartAtSelectDateProps = {
  yearsProps: SelectProps,
  monthsProps: SelectProps,
}

export type VStartAtCalendarDateProps = {
  ref: React.ForwardedRef<HTMLDivElement>,
  props: React.PropsWithChildren<FancyButtonProps>,
};

const StartAtDate = ({ type, dateString, onChangeDate }: StartAtDateProps) => {
  const [date, setDate] = useState(dayjs(dateString));

  let years = new Array<selectOption>();
  for (let i = 0; i < 3; i++) {
    years.push({ label: String(dayjs().year() + i), value: String(dayjs().year() + i) });
  }

  let months = new Array<selectOption>();
  for (let i = 1; i <= 12; i++) {
    months.push({ label: `${i}월`, value: `${i}` });
  }

  const vStartAtSelectDateProps: VStartAtSelectDateProps = {
    yearsProps: {
      selectOption: [{ label: String(date.year()), value: String(date.year()) }],
      selectOptions: years,
      placeholder: '연도',
      onChangeOption: (newValue) => {
        const y = newValue as SingleValue<selectOption>;
        if (y) {
          setDate(date.year(Number(y.value)));
          onChangeDate(date.year(Number(y.value)).format('YYYY-MM'));
        }
      },
    },
    monthsProps: {
      selectOption: [{ label: `${date.month() + 1}월`, value: String(date.month()) }],
      selectOptions: months,
      placeholder: '월',
      onChangeOption: (newValue) => {
        const m = newValue as SingleValue<selectOption>;
        if (m) {
          setDate(date.month(Number(m.value) - 1));
          onChangeDate(date.month(Number(m.value) - 1).format('YYYY-MM'));
        }
      },
    },
  };

  const Component = forwardRef<HTMLDivElement, React.PropsWithChildren<FancyButtonProps>>(
    (props, ref) => (
      <VStartAtCalendarDate ref={ref} props={props} />
    )
  );

  return (
    <>
      {
        type === 0 ?
          <VStartAtSelectDate {...vStartAtSelectDateProps} /> :
          <DatePicker dateFormat={'yyyy/MM/dd'} selected={date.toDate()}
                      onChange={(date) => {
                        if (date) {
                          setDate(dayjs(date as Date));
                          onChangeDate(dayjs(date as Date).format('YYYY-MM-DD'));
                        }
                      }}
                      customInput={<Component />} />
      }
    </>
  )
};

export default StartAtDate;
