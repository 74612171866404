import React from 'react';
import ContentContainer, { ContentProps } from '../../../components/content_container';
import SchedulesContainer from '../../../components/projects/containers/schedules_container';

const ScheduleContent = () => {
  const contentsProps: ContentProps[] = [
    {
      title: '여행 일정',
      description: '세부 여행 일정과 숙박 정보를 입력해 주세요. 세부 일정과 숙소는 논의 후 변경될 수 있습니다. ',
      columns: [
        {
          component: <SchedulesContainer />
        }
      ]
    }
  ];

  return (
    <>
      {
        contentsProps.map((contentProps, i) => <ContentContainer key={i} {...contentProps} />)
      }
    </>
  )
};

export default ScheduleContent;
