import React from 'react';
import styles from './v_option.module.css';
import check from '../../../static/images/check.svg';

export type VOptionProps = {
  innerProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  isSelected: boolean,
  label: string,
};

const VOption = ({ innerProps, isSelected, label }: VOptionProps) => {
  return (
    <div {...innerProps} className={styles.container}>
      {
        label
      }
      {
        isSelected && <img src={check} alt={'check'} className={styles.icon} />
      }
    </div>
  )
};

export default VOption;
