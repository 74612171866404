import React from 'react';
import styles from './v_image_uploaded.module.css';
import cancel from '../../../static/images/upload_cancel.svg';
import { VImageUploadedProps } from '../image_upload';

const VImageUploaded = ({ file, onRemoveFile }: VImageUploadedProps) => {
  return (
    <div className={styles.container}>
      <img src={cancel} alt={'cancel'} className={styles.icon_cancel} onClick={onRemoveFile} />
      {
        file && <img src={URL.createObjectURL(file)} alt={'image'} className={styles.image} />
      }
    </div>
  )
};

export default VImageUploaded;
