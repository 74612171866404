import React from 'react';

import Home from '../pages/home';

import NewProject from "../pages/projects/new";

export interface RouteProps {
    path: string,
    component: React.FC
}

export const projectRoutes: Array<RouteProps> = [
    {
        path: '/projects/new',
        component: NewProject,
    }
];

export const routes: Array<RouteProps> = [
    {
        path: '/',
        component: Home,
    },
];
