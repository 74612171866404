import React from 'react';
import styles from './v_city_container.module.css';
import SelectCity from '../selects/select_city';
import MinusWhite from '../../static/images/minus_white.svg';
import MinusBlack from '../../static/images/minus_black.svg';
import PlusWhite from '../../static/images/plus_white.svg';
import PlusBlack from '../../static/images/plus_black.svg';
import { VCityContainerProps } from '../city_container';

const VCityContainer = ({
                          city, options,
                          isHoverMinus, setHoverMinus,
                          isHoverPlus, setHoverPlus,
                          onIncrease, onDecrease, onDelete
                        }: VCityContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.select_city_container}>
        <SelectCity {...options} />
        {
          city && city.name && <p className={styles.select_city}>{`${city.name} ${city.days > 0 ? city.days : ''}`}</p>
        }
      </div>
      <div className={styles.day_container}>
        <img src={isHoverMinus ? MinusBlack : MinusWhite} alt={'minus'} className={styles.lodging_button}
             onClick={onDecrease} onMouseOver={() => setHoverMinus(true)} onMouseOut={() => setHoverMinus(false)} />
        <p className={styles.day_count}>{city.days}</p>
        <img src={isHoverPlus ? PlusBlack : PlusWhite} alt={'plus'} className={styles.lodging_button}
             onClick={onIncrease} onMouseOver={() => setHoverPlus(true)} onMouseOut={() => setHoverPlus(false)} />
        <p className={styles.lodging_cancel} onClick={onDelete}>삭제</p>
      </div>
    </div>
  )
};

export default VCityContainer;
