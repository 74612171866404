import React from 'react';
import styles from './v_lodging_content_container.module.css';
import RadioContainer from '../radio_container';
import Input from '../projects/Input';
import { VLodgingContentContainerProps } from '../lodging_content_container';

const VLodgingContentContainer = ({
                                    lodgingTags, lodging,
                                    nameInputProps, addressInputProps, homepageInputProps
                                  }: VLodgingContentContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.type_container}>
        <p className={styles.sub_title}>{lodging.city.name} 에서는 어떤 숙소를 사용할 예정이신가요?</p>
        <div className={styles.types_container}>
          <RadioContainer {...lodgingTags} />
        </div>
      </div>
      {
        lodging.type === 11 &&
        <div>
          <p className={styles.sub_title}>[추천하고 싶은 숙소] 이름을 알려주세요.</p>
          <div className={styles.input_container}>
            <Input {...nameInputProps} />
          </div>
          <div className={styles.inputs_container}>
            <p className={styles.sub_title}>[추천하고 싶은 숙소] 구글 검색 시 나오는 현지 주소를 입력해 주세요.</p>
            <div className={styles.input_container}>
              <Input {...addressInputProps} />
            </div>
          </div>
          <div className={styles.inputs_container}>
            <p className={styles.sub_title}>[추천하고 싶은 숙소] 정보를 볼 수 있는 홈페이지 주소를 알려주세요.</p>
            <div className={styles.input_container}>
              <Input {...homepageInputProps} />
            </div>
          </div>
        </div>
      }
    </div>
  )
};

export default VLodgingContentContainer;
