import React from 'react';
import VHorizontalTagsContainer from './vac/v_horizontal_tags_container';

export type HorizontalTagsContainerProps = {
  values: string[],
  index: number,
  setIndex: (index: number) => void,
};

export type VHorizontalTagsContainerProps = {
  values: string[],
  index: number,
  isPrevDisable: boolean,
  isNextDisable: boolean,
  onSelectIndex: (index: number) => void,
  onPrevIndex: () => void,
  onNextIndex: () => void,
}

const HorizontalTagsContainer = ({ index, setIndex, values }: HorizontalTagsContainerProps) => {
  const vHorizontalTagsContainerProps: VHorizontalTagsContainerProps = {
    values: values,
    index: index,
    isPrevDisable: index === 0,
    isNextDisable: index === values.length - 1,
    onSelectIndex: (index) => setIndex(index),
    onPrevIndex: () => index !== 0 && setIndex(index - 1),
    onNextIndex: () => index !== values.length - 1 && setIndex(index + 1),
  };

  return (
    <>
      <VHorizontalTagsContainer {...vHorizontalTagsContainerProps} />
    </>
  )
};

export default HorizontalTagsContainer;
