import React, { useState } from 'react';
import VImageUpload from './vac/v_image_upload';
import VImageUploaded from './vac/v_image_uploaded';
import AlertContainer from './containers/alert_container';

export type ImageUploadProps = {
  file: File | null,
  setFile: (file: File | null) => void,
  alert?: string,
}

export type VImageUploadProps = {
  onChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

export type VImageUploadedProps = {
  file: File | null,
  onRemoveFile: () => void,
};

const ImageUpload = ({ file, setFile, alert }: ImageUploadProps) => {
  const [message, setMessage] = useState(alert ? alert : '');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const vImageUploadProps: VImageUploadProps = {
    onChangeFile: (event) => {
      event.preventDefault();
      if (event.target.files) {
        const sizeInMB = (event.target.files[0].size / (1024 * 1024));
        if (sizeInMB > 3) {
          setFile(null);
          setErrorMessage('3MB 초과 했습니다.');
        } else {
          setFile(event.target.files[0]);
          setMessage(alert ? alert : '');
          setErrorMessage(undefined);
        }
      }
    },
  };

  const vImageUploadedProps: VImageUploadedProps = {
    file: file,
    onRemoveFile: () => {
      setFile(null);
      setMessage(alert ? alert : '');
    }
  };

  return (
    <>
      {
        file ? <VImageUploaded {...vImageUploadedProps} /> : <VImageUpload {...vImageUploadProps} />
      }
      {
        alert && !errorMessage && <AlertContainer alert={message} />
      }
      {
        errorMessage && <AlertContainer alert={message} error={errorMessage} />
      }
    </>
  )
};

export default ImageUpload;
