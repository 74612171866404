import React from 'react';
import styles from './v_lodging_schedule.module.css';
import HorizontalTagsContainer from '../containers/horizontal_tags_container';
import { VLodgingScheduleProps } from '../lodging_schedule';
import LodgingContentContainer from '../../lodging_content_container';

const VLodgingSchedule = ({ index, setIndex, lodgings, values, onUpdateLodging }: VLodgingScheduleProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>생각하고 계신 숙소가 있나요? 도시별로 알려주세요. </p>
      <div className={styles.tags_container}>
        <HorizontalTagsContainer index={index} setIndex={setIndex} values={values} />
      </div>
      <LodgingContentContainer index={index} lodgings={lodgings} onUpdateLodging={onUpdateLodging} />
    </div>
  )
};

export default VLodgingSchedule;
