import React from 'react';
import { VColumnContainerProps } from '../column_container';
import styles from './v_column_container.module.css';

const VColumnContainer = ({ title, component, actionComponent }: VColumnContainerProps) => {
  return (
    <div className={styles.container}>
      {
        title && <p className={styles.title}>{title}</p>
      }
      {
        component
      }
      {
        actionComponent
      }
    </div>
  )
};

export default VColumnContainer;
