import React from 'react';
import VColumnContainer from './vac/v_column_container';
import { ColumnProps } from '../../content_container';

export type VColumnContainerProps = {
  title?: string,
  component: JSX.Element,
  actionComponent?: JSX.Element,
};

const ColumnContainer = ({ title, component, actionComponent }: ColumnProps) => {
  const VColumnContainerProps: VColumnContainerProps = {
    title: title,
    component: component,
    actionComponent: actionComponent,
  };

  return (
    <>
      <VColumnContainer {...VColumnContainerProps} />
    </>
  )
};

export default ColumnContainer;
