import React from 'react';
import styles from './v_button_radio_container.module.css';
import classNames from 'classnames';
import { VRadioContainerProps } from '../radio_container';

const VButtonRadioContainer = ({ list, each }: VRadioContainerProps) => {
  return (
    <div className={styles.container}>
      {
        list.map((item, index) => {
          const { onClickItem, isActive } = each(item, index);
          return (
            <div key={index} onClick={onClickItem}
                 className={classNames(styles.button, isActive() ? styles.button_active : '')}>{item}</div>
          )
        })
      }
    </div>
  )
};

export default VButtonRadioContainer;
