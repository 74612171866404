import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteProps } from './data';
import useLocalStorage from '../utils/storage';

export interface AuthRouteProps {
    route: RouteProps
}

const AuthRoute = ({route}: AuthRouteProps) => {
    // const { storedValue } = useLocalStorage('user');
    // const [isLoggedIn, setIsLoggedIn] = useState(() => !!storedValue);
    //
    // useEffect(() => {
    //     setIsLoggedIn(!!storedValue);
    // }, [storedValue]);
    const isLoggedIn = true

    return isLoggedIn ?
        <Route exact path={route.path} component={route.component} /> :
        <Redirect to="/login" />

}

export default AuthRoute;
