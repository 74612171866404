import React from 'react';
import styles from './v_short_preview_project.module.css';
import { VShortPreviewProjectProps } from '../short_preview_project';

const VShortPreviewProject = ({ thumbnail, tags, name, host, keywords, deadline }: VShortPreviewProjectProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.image_container}>
        <p className={styles.new_icon}>NEW</p>
        {
          thumbnail && <img src={URL.createObjectURL(thumbnail)} alt={'image'} className={styles.project_img} />
        }
      </div>
      <div className={styles.content_container}>
        <div className={styles.tags_container}>
          {
            tags.map((tag, i) => <p key={i} className={styles.tag}>{tag}</p>)
          }
        </div>
        <p className={styles.title}>{name}</p>
        <div className={styles.host_container}>
          {
            host.image ? <img src={URL.createObjectURL(host.image)} alt={'image'} className={styles.host_image} /> :
              <div className={styles.no_host_image} />
          }
          <p className={styles.host_name}>{host.name}</p>
        </div>
        <div className={styles.keywords_container}>
          {
            keywords.map((keyword, i) => <p key={i} className={styles.keyword}>#{keyword}</p>)
          }
        </div>
        <p className={styles.deadline}>{deadline}</p>
      </div>
    </div>
  )
};

export default VShortPreviewProject;
