import React, { useEffect } from 'react';
import VTotalSchedule from './vac/v_total_schedule';
import AlertContainer from './containers/alert_container';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/modules';
import { updateProject } from '../../redux/modules/project';
import { ProjectCity, ProjectSchedule } from '../../models/project';

export type VTotalScheduleProps = {
  cities: ProjectCity[],
  onCreateCity: () => void,
  onUpdateCity: (index: number, city: ProjectCity) => void,
  onDeleteCity: (index: number) => void,
};

const TotalSchedule = () => {
  const dispatch = useDispatch();

  const project = useSelector((state: RootState) => state.project.project);

  const updateCities = (cities: ProjectCity[]) => dispatch(updateProject({
    ...project,
    cities: cities,
  }));

  const vTotalScheduleProps: VTotalScheduleProps = {
    cities: project.cities,
    onCreateCity: () => updateCities(project.cities.concat({ name: '', days: 0, country: '', area: '' })),
    onUpdateCity: (index, city) => {
      let temp = Object.assign(new Array<ProjectCity>(project.cities.length), project.cities);
      temp[index] = city;
      updateCities(temp);
    },
    onDeleteCity: (index) => updateCities(project.cities.filter((_, i) => i !== index))
  };

  const getCityName = (index: number, cities: ProjectCity[]) => {
    if (index <= cities[0].days) {
      return cities[0].name;
    } else {
      for (let i = 0; i < cities.length; i++) {
        if (i !== 0) {
          const days = cities.reduce((prev, city, current_index) => i <= current_index ? prev : prev + city.days, 0);
          const new_index = index - days;
          if (new_index === 1) {
            return cities[i - 1].name + ' ~ ' + cities[i].name;
          } else if (new_index <= cities[i].days) {
            return cities[i].name
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(updateProject({
      ...project,
      schedules: new Array<ProjectSchedule>(project.cities.reduce((prev, city) => prev + city.days, 0))
        .fill({ day: 0, value: '', typeMobility: 1, mobility: '', city_name: '' })
        .map((city, i) => ({
          day: i + 1,
          value: '',
          city_name: getCityName(i + 1, project.cities),
          typeMobility: 1,
          mobility: null,
        })),
      lodgings: project.cities.map((city, i) => ({
        city: city,
        type: 0,
        name: '',
        address: '',
        homepage: '',
      })),
    }));
  }, [project.cities]);

  return (
    <>
      <VTotalSchedule {...vTotalScheduleProps} />
      <AlertContainer alert={'숙박을 하지 않는 경유도시는 작성하지 않습니다.\n도시별 숙박 순서대로 작성해 주세요.'} />
    </>
  )
};

export default TotalSchedule;
