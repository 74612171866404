import React from 'react';
import classNames from 'classnames';
import styles from './v_horizontal_tags_container.module.css';
import prev from '../../../../static/images/prev_button.svg';
import next from '../../../../static/images/next_button.svg';
import { VHorizontalTagsContainerProps } from '../horizontal_tags_container';

const VHorizontalTagsContainer = ({
                                    values, index, isPrevDisable, isNextDisable, onSelectIndex, onPrevIndex, onNextIndex
                                  }: VHorizontalTagsContainerProps) => {
  return (
    <div className={styles.container}>
      <img src={prev} alt={'prev'} className={classNames(styles.icon, isPrevDisable && styles.disable_icon)}
           onClick={onPrevIndex} />
      <div className={styles.tags_container}>
        {
          values.map((value, i) =>
            <p key={i} className={classNames(styles.tag, i === index ? styles.tag_active : '')}
               onClick={() => onSelectIndex(i)}>{value}</p>
          )
        }
      </div>
      <img src={next} alt={'next'} className={classNames(styles.right_icon, isNextDisable && styles.disable_icon)}
           onClick={onNextIndex} />
    </div>
  )
};

export default VHorizontalTagsContainer;
