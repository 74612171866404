import React from 'react';
import VButton from './vac/v_button';

export type ButtonProps = {
  value: string,
  isDisable: boolean,
  onClick: () => void,
};

export type VButtonProps = {
  value: string;
  isDisable: boolean,
  onClick: () => void
};

const Button = ({ value, isDisable, onClick }: ButtonProps) => {
  const vButtonProps: VButtonProps = {
    value: value,
    isDisable: isDisable,
    onClick: onClick
  };

  return (
    <>
      <VButton {...vButtonProps} />
    </>
  )
};

export default Button;
