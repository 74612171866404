import React from 'react';
import styles from './v_start_at_select_date.module.css';
import SelectDate from '../../selects/select_date';
import { VStartAtSelectDateProps } from '../start_at_date';

const VStartAtSelectDate = ({ yearsProps, monthsProps }: VStartAtSelectDateProps) => {
  return (
    <div className={styles.container}>
      <SelectDate {...yearsProps} />
      <SelectDate {...monthsProps} />
    </div>
  )
};

export default VStartAtSelectDate;
