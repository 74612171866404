import React from 'react';
import VPreviewProject from './vac/v_preview_project';
import { Project, ProjectCity, ProjectHost, ProjectImageText } from '../models/project';
import dayjs from 'dayjs';

const getCities = (projectCities: ProjectCity[]) => {
  const areas = new Set(Array.from(projectCities.map(city => city.area)));
  if (areas.size > 1) {
    return '전세계';
  }

  const countries = new Set(Array.from(projectCities.map(city => city.country)));
  if (countries.size > 1) {
    return projectCities[0].area;
  }

  const cities = new Set(Array.from(projectCities.map(city => city.name)));
  if (cities.size > 1) {
    return projectCities[0].country;
  }

  return projectCities.map(city => city.name);
};

const getGender = (type: number) => {
  switch (type) {
    case 0:
      return '남자만';
    case 1:
      return '여자만';
    case 2:
      return '남녀참가';
  }
};

const getAge = (type: number, min: number | null, max: number | null) => {
  if (type === 0) {
    return '전연령참가';
  } else if (min && max) {
    return min === max ? `${min}살` : `${min ? min : ''}-${max ? max : ''}`;
  } else {
    return '값이 비어있습니다.';
  }
};

export type PreviewProjectProps = {
  project: Project
};

export type VPreviewProjectProps = {
  tags: string[],
  name: string,
  host: ProjectHost,
  keywords: string[],
  deadline: string,
  imageTexts: ProjectImageText[][],
};

const PreviewProject = ({ project }: PreviewProjectProps) => {
  const vPreviewProjectProps: VPreviewProjectProps = {
    tags: new Array<string>()
      .concat(getCities(project.cities))
      .concat(
        `${dayjs(project.atGoOff).month() + 1}월예정`,
        `${getGender(project.userInfo.genderType)}`,
        `${getAge(project.userInfo.ageType, project.userInfo.ageMin, project.userInfo.ageMax)}`
      ),
    name: project.name,
    host: project.host,
    keywords: project.keywords,
    deadline: '1차오픈마감 00일 00시 00분 남음',
    imageTexts: [project.imagePlans, project.imageSpecial, project.imageWithPeople, project.host.imageHostInfo]
  };

  return (
    <>
      <VPreviewProject {...vPreviewProjectProps} />
    </>
  )
};

export default PreviewProject;
