import React from 'react';
import styles from './v_temp_save_modal.module.css';
import classNames from 'classnames';
import { VTempSaveModalProps } from '../temp_save_modal';

const VTempSaveModal = ({ onClickClose, onClickSave }: VTempSaveModalProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>정말 나가시겠습니까?</p>
      <p className={styles.content}>
        호스트님과 함께할 여행을 기다리고 있어요.
        <br />
        작성 중인 페이지를 마저 완료해 보면 어떨까요? 🕺🏻 😉
      </p>
      <div className={styles.buttons_container}>
        <button onClick={onClickClose} className={classNames(styles.button, styles.white)}>나가기</button>
        <button onClick={onClickSave} className={classNames(styles.button, styles.dark)}>네, 마저 작성할게요</button>
      </div>
    </div>
  )
};

export default VTempSaveModal;
