import React from 'react';
import styles from './v_content_container.module.css';
import SubTitle from '../projects/sub_title';
import ColumnContainer from '../projects/containers/column_container';
import { VContentContainerProps } from '../content_container';

const VContentContainer = ({ contentProps }: VContentContainerProps) => {
  return (
    <div className={styles.container}>
      <SubTitle title={contentProps.title} description={contentProps.description} />
      {
        contentProps.columns.map((column, i) =>
          <ColumnContainer key={i} {...column} />
        )
      }
    </div>
  )
};

export default VContentContainer;
