import React from 'react';
import classNames from 'classnames';
import styles from './v_schedules_container.module.css';
import ScheduleContainers from '../schedule_containers';
import { VScheduleContainersProps } from '../schedules_container';

const VScheduleContainers = ({ tabs, tabIndex, onChangeTab }: VScheduleContainersProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.tab_container}>
        {
          tabs.map((t, index) =>
            <div key={index} style={{ zIndex: 3 - index }} onClick={() => onChangeTab(index)}
                 className={classNames(styles.tab, index !== 0 ? styles.tab_margin : '', tabIndex === index ? styles.tab_active : '')}>
              <p className={styles.tab_text}>{t}</p>
            </div>
          )
        }
      </div>
      <ScheduleContainers tabIndex={tabIndex} />
    </div>
  )
};

export default VScheduleContainers;
