import React, { useState } from 'react';
import VInputGender from './vac/v_input_gender';
import AlertContainer from './containers/alert_container';

export type InputGenderProps = {
  reason: string,
  alert: string,
  onChangeReason: (reason: string) => void,
};

export type VInputGenderProps = {
  reason: string,
  error: string | null,
  onChangeReason: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
}

const InputGender = ({ reason, alert, onChangeReason }: InputGenderProps) => {
  const [error, setError] = useState<string | null>(reason ? null : '값이 비어있습니다.');

  const vInputGenderProps: VInputGenderProps = {
    reason: reason,
    error: error,
    onChangeReason: (event) => {
      onChangeReason(event.target.value);
      if (event.target.value === '') {
        setError(alert);
      } else {
        setError(null);
      }
    }
  }

  return (
    <>
      <VInputGender {...vInputGenderProps} />
      {
        error && <AlertContainer alert={alert} error={error} />
      }
    </>
  )
};

export default InputGender
