import React from 'react';
import ContentContainer, { ContentProps } from '../../../components/content_container';
import Input, { InputProps } from '../../../components/projects/Input';
import ImageTextUploadContainer, {
  ImageTextUploadContainerProps
} from '../../../components/projects/containers/image_text_upload_container';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/modules';
import { updateProject } from '../../../redux/modules/project';

const HostContent = () => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.project);

  const inputProps: InputProps = {
    value: project.host.name,
    placeholder: '닉네임을 입력해주세요',
    alert: '실명 혹은 닉네임을 써주세요.',
    onChangeValue: (value: string) => dispatch(updateProject({
      ...project,
      host: {
        ...project.host,
        name: value
      }
    })),
  };

  const imageHostInfosContainerProps: ImageTextUploadContainerProps = {
    imageTexts: project.host.imageHostInfo.map(plan => ({
      file: plan.file,
      value: plan.value,
      placeholder: '글을 작성해 주세요'
    })),
    setImageTexts: (imageTexts) => dispatch(updateProject({
      ...project,
      host: {
        ...project.host,
        imageHostInfo: imageTexts.map(imageText => ({ file: imageText.file, value: imageText.value }))
      }
    })),
    alert: '3MB 이하의 JPEG, PNG 파일 해상도 1200X675 픽셀 이상'
  };

  const contentsProps: ContentProps[] = [
    {
      title: '호스트 소개',
      description: '함께 여행할 당신이 어떤 사람인지 너무 궁금해요:) 솔직하게 자신의 매력을 맘껏 표현해 주세요.',
      columns: [
        {
          title: '호스트 닉네임',
          component: <Input {...inputProps} />,
        },
        {
          title: '호스트를 소개해주세요',
          component: <ImageTextUploadContainer {...imageHostInfosContainerProps} />,
        },
      ]
    }
  ];

  return (
    <>
      {
        contentsProps.map((contentProps, i) => <ContentContainer key={i} {...contentProps} />)
      }
    </>
  )
};

export default HostContent;
