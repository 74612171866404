import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { SelectProps, styles } from './select';

const SelectKeyword = ({ selectOption, selectOptions, placeholder, onChangeOption }: SelectProps) => {
  return <CreatableSelect isMulti={true} defaultValue={selectOption} options={selectOptions} value={selectOption}
                          noOptionsMessage={() => '키워드를 입력하세요.'}
                          styles={styles(610)} placeholder={placeholder} onChange={onChangeOption} />
};

export default SelectKeyword;
