import React from 'react';
import styles from './v_input_age.module.css';
import { VInputAgeProps } from '../input_age';

const VInputAge = ({ minAge, maxAge, onChangeMinAge, onChangeMaxAge }: VInputAgeProps) => {
  return (
    <div className={styles.container}>
      <input className={styles.input} type="text" pattern="\d*" maxLength={2} inputMode={'numeric'}
             placeholder={'최소 나이'} value={minAge} onChange={onChangeMinAge} />
      <p className={styles.input_center}>~</p>
      <input className={styles.input} type="text" pattern="\d*" maxLength={2} inputMode={'numeric'}
             placeholder={'최대 나이'} value={maxAge} onChange={onChangeMaxAge} />
    </div>
  )
};

export default VInputAge;
