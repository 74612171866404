import React, { useEffect, useState } from 'react';
import styles from './new.module.css';
import Sidebar, { SideBarProps } from '../../components/projects/sidebar';
import ButtonsContainer, { ButtonsContainerProps } from '../../components/buttons_container';
import Contents from './contents';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/modules';
import { setScheduleStep, setStep } from '../../redux/modules/project';
import ShortPreviewProject, { ShortPreviewProjectProps } from '../../components/short_preview_project';
import PreviewProject, { PreviewProjectProps } from '../../components/preview_project';
import Modal, { ModalProps } from '../../components/modals/modal';
import TempSaveModal from '../../components/modals/temp_save_modal';
import ProjectSaveModal from '../../components/modals/project_save_modal';
import ProjectHeader from '../../components/projects/header';
import { useHistory } from 'react-router-dom';

const New = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const step = useSelector((state: RootState) => state.project.step);
  const scheduleStep = useSelector((state: RootState) => state.project.scheduleStep);
  const project = useSelector((state: RootState) => state.project.project);

  const [disable, setDisable] = useState(false);

  const [isOpenTempSave, setIsOpenTempSave] = useState(false);
  const [isOpenSave, setIsOpenSave] = useState(false);

  const modalProps: ModalProps = {
    isOpen: isOpenTempSave,
    setIsOpen: setIsOpenTempSave,
    component: <TempSaveModal onClose={() => {
      setIsOpenTempSave(false);
      alert('임시저장이 완료되었습니다.');
      history.goBack();
    }} onSave={() => setIsOpenTempSave(false)} />
  };

  const saveModalProps: ModalProps = {
    isOpen: isOpenSave,
    setIsOpen: setIsOpenSave,
    component: <ProjectSaveModal onClose={() => setIsOpenSave(false)} onSave={() => {
      setIsOpenSave(false);
      alert('저장이 완료되었습니다.');
    }} />
  };

  const sideBarProps: SideBarProps = {
    step: step,
    onChangeStep: (value) => {
      dispatch(setStep(value));
    },
  };

  const buttonsContainerProps: ButtonsContainerProps = {
    buttonsProps: [
      {
        value: '임시저장',
        isDisable: false,
        onClick: () => alert('임시저장이 완료되었습니다.'),
      },
      {
        value: step === 3 ? '최종저장' : '다음',
        isDisable: disable,
        onClick: () => step === 3 ?
          (scheduleStep === 2 ? setIsOpenSave(true) : dispatch(setScheduleStep(scheduleStep + 1)))
          : dispatch(setStep(step + 1))
      },
    ],
    error: disable ? '입력되지 않은 필수 입력값이 있습니다. 모든 항목을 입력해야 [다음]으로 이동할 수 있습니다.' : '',
  };

  const shortPreviewProjectProps: ShortPreviewProjectProps = {
    project: project
  };

  const previewProjectProps: PreviewProjectProps = {
    project: project
  };

  const onCheckProjectSava = () => {
    let check = false;
    if (project.userInfo.ageType === 1) {
      check = check || project.userInfo.ageMin === null || project.userInfo.ageMax === null;
      if (project.userInfo.ageMin && project.userInfo.ageMax) {
        check = check || project.userInfo.ageMin > project.userInfo.ageMax;
      }
    }
    if (project.userInfo.genderType !== 2) {
      check = check || project.userInfo.genderReason === '';
    }

    check = check || project.name === '' || project.thumbnail === null;
    check = check || project.keywords.length === 0 || project.keywords.length > 6 || project.keywords.length < 3;
    check = check || project.imagePlans.filter(image => image.file !== null && image.value !== '').length === 0;
    check = check || project.imageSpecial.filter(image => image.file !== null && image.value !== '').length === 0;
    check = check || project.imageWithPeople.filter(image => image.file !== null && image.value !== '').length === 0;

    check = check || project.host.name === '';
    check = check || project.host.imageHostInfo.filter(image => image.file !== null && image.value !== '').length === 0;

    check = check || project.cities.length === 0
    if (project.cities.length !== 0) {
      check = check || project.cities.map(city => city.name === '' || city.days === 0).reduce((prev, value) => prev || value);
    }

    if (project.schedules.length !== 0) {
      check = check || project.schedules.map(schedule => schedule.value === '' ||
      schedule.typeMobility === 0 ? schedule.mobility === null : false)
        .reduce((prev, value) => prev || value);
    }

    if (project.lodgings.length !== 0) {
      check = check || project.lodgings.map(lodging => lodging.type === 11 ?
        (lodging.name === '' || lodging.address === '' || lodging.homepage === '') : false)
        .reduce((prev, value) => prev || value);
    }
    return check;
  };

  useEffect(() => {
    let check = false;
    switch (step) {
      case 0:
        if (project.userInfo.ageType === 1) {
          check = check || project.userInfo.ageMin === null || project.userInfo.ageMax === null;
          if (project.userInfo.ageMin && project.userInfo.ageMax) {
            check = check || project.userInfo.ageMin > project.userInfo.ageMax;
          }
        }
        if (project.userInfo.genderType !== 2) {
          check = check || project.userInfo.genderReason === '';
        }
        break;
      case 1:
        check = check || project.name === '' || project.thumbnail === null;
        check = check || project.keywords.length === 0 || project.keywords.length > 6 || project.keywords.length < 3;
        check = check || project.imagePlans.filter(image => image.file !== null && image.value !== '').length === 0;
        check = check || project.imageSpecial.filter(image => image.file !== null && image.value !== '').length === 0;
        check = check || project.imageWithPeople.filter(image => image.file !== null && image.value !== '').length === 0;
        break;
      case 2:
        check = check || project.host.name === '';
        check = check || project.host.imageHostInfo.filter(image => image.file !== null && image.value !== '').length === 0;
        break;
      case 3:
        switch (scheduleStep) {
          case 0:
            check = check || project.cities.length === 0
            if (project.cities.length !== 0) {
              check = check || project.cities.map(city => city.name === '' || city.days === 0).reduce((prev, value) => prev || value);
            }
            break;
          case 1:
            if (project.schedules.length !== 0) {
              check = check || project.schedules.map(schedule => schedule.value === '' ||
              schedule.typeMobility === 0 ? schedule.mobility === null : false)
                .reduce((prev, value) => prev || value);
            }
            break;
          case 2:
            // if (project.lodgings.length !== 0) {
            //   check = check || project.lodgings.map(lodging => lodging.type === 11 ?
            //     (lodging.name === '' || lodging.address === '' || lodging.homepage === '') : false)
            //     .reduce((prev, value) => prev || value);
            // }
            check = check || onCheckProjectSava();
            break;
        }
        break;
    }
    setDisable(check);
  }, [step, scheduleStep, project]);

  return (
    <>
      <ProjectHeader onClickBack={() => setIsOpenTempSave(true)} />
      <div className={styles.container}>
        <Modal {...modalProps} />
        <Modal {...saveModalProps} />
        <Sidebar {...sideBarProps} />
        <div className={styles.contents_container}>
          {
            Contents[step]
          }
          <ButtonsContainer {...buttonsContainerProps} />
        </div>
        <div className={styles.previews_container}>
          <div className={styles.short_preview_container}>
            <ShortPreviewProject {...shortPreviewProjectProps} />
          </div>
          <div className={styles.preview_container}>
            <PreviewProject {...previewProjectProps} />
          </div>
        </div>
      </div>
    </>
  )
};

export default New;
