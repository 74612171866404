import React from 'react';
import styles from './v_image_text_upload_container.module.css';
import { VImageTextUploadContainerProps } from '../image_text_upload_container';
import ImageUpload from '../../image_upload';

const VImageTextUploadContainer = ({
                                     value, onChangeValue, placeholder, imageUploadProps
                                   }: VImageTextUploadContainerProps) => {
  return (
    <div className={styles.container}>
      <ImageUpload {...imageUploadProps} />
      <textarea className={styles.textarea} value={value} onChange={onChangeValue} placeholder={placeholder} />
    </div>
  )
};

export default VImageTextUploadContainer;
