import React from 'react';
import VSideBar from './vac/v_side_bar';

export type SideBarProps = {
  step: number,
  onChangeStep: (value: number) => void,
};

export type VSideBarProps = {
  list: string[],
  each: (item: string, index: number) => {
    isActive: () => boolean,
    onClickItem: () => void,
  },
};

const Sidebar = ({ step, onChangeStep }: SideBarProps) => {
  const vSideBarProps: VSideBarProps = {
    list: ['여행 기본 정보', '여행 설명', '호스트 소개', '여행 일정'],
    each: (item: string, index: number) => ({
      isActive: () => index === step,
      onClickItem: () => onChangeStep(index),
    }),
  };

  return (
    <>
      <VSideBar {...vSideBarProps} />
    </>
  )
};

export default Sidebar;
