import React from 'react';
import { ActionMeta, MultiValue, SingleValue, StylesConfig } from 'react-select';

export type selectOption = {
  label: string,
  value: string
};

export type SelectProps = {
  selectOption?: selectOption[],
  selectOptions: selectOption[],
  placeholder: string,
  onChangeOption: (newValue: SingleValue<selectOption> | MultiValue<selectOption>, actionMeta: ActionMeta<selectOption>) => void,
};

export const styles: (width: number) => StylesConfig<selectOption> = (width) => ({
  control: () => ({
    display: 'flex',
    width: `${width}px`,
    minHeight: '40px',
    border: '1px solid #4F4F4F',
    boxSizing: 'border-box',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginRight: 20,
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginLeft: 8,
    width: `${width - 80}px`,
    flexWrap: 'wrap',
  }),
  singleValue: () => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '13px',
    color: '#4F4F4F',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
    '&:hover': {
      color: 'hsl(0, 0%, 20%)',
      backgroundColor: 'hsl(0, 0%, 90%)'
    }
  }),
  menu: (provided) => ({
    ...provided,
    width: `${width}px`,
    border: '1px solid #707070',
  }),
  menuList: () => ({
    width: '100%',
    textAlign: 'center',
  }),
  placeholder: () => ({
    position: 'absolute',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '13px',
    color: '#6C6C6C',
    marginLeft: 2
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    width: `${width}px`,
    height: '40px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '13px',
    color: '#4F4F4F',
    marginLeft: 8
  }),
  noOptionsMessage: () => ({
    display: 'flex',
    alignItems: 'center',
    width: `${width}px`,
    height: '40px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '13px',
    color: '#4F4F4F',
    marginLeft: 8
  }),
});
