import React from 'react';
import VContentContainer from './vac/v_content_container';

export type ColumnProps = {
  title?: string,
  component: JSX.Element,
  actionComponent?: JSX.Element,
};

export type ContentProps = {
  title: string,
  description: string,
  columns: ColumnProps[]
};

export type VContentContainerProps = {
  contentProps: ContentProps
};

const ContentContainer = (contentProps: ContentProps) => {
  const vContentContainerProps: VContentContainerProps = {
    contentProps: contentProps
  };

  return (
    <>
      <VContentContainer {...vContentContainerProps} />
    </>
  )
};

export default ContentContainer;
