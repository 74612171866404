import React from 'react';
import styles from './v_button.module.css';
import { VButtonProps } from '../button';

const VButton = ({ value, isDisable, onClick }: VButtonProps) => {
  return (
    <button disabled={isDisable} className={styles.button} onClick={onClick}>{value}</button>
  )
};

export default VButton;
