import { Project, ProjectCity, ProjectLodging, ProjectSchedule } from '../../models/project';
import dayjs from 'dayjs';

const SET_STEP = 'project/SET_STEP' as const;
const UPDATE_PROJECT = 'project/UPDATE_PROJECT' as const;
const SET_SCHEDULE_STEP = 'project/SET_SCHEDULE_STEP' as const;

export const setStep = (step: number) => ({
  type: SET_STEP,
  payload: step,
});

export const updateProject = (project: Project) => ({
  type: UPDATE_PROJECT,
  payload: project
});

export const setScheduleStep = (step: number) => ({
  type: SET_SCHEDULE_STEP,
  payload: step
});

type ProjectAction =
  | ReturnType<typeof setStep>
  | ReturnType<typeof updateProject>
  | ReturnType<typeof setScheduleStep>
  ;

type ProjectState = {
  step: number,
  scheduleStep: number,
  project: Project
};

const initialState: ProjectState = {
  step: 0,
  scheduleStep: 0,
  project: {
    season: 0,
    typeGoOff: 0,
    atGoOff: dayjs().format('YYYY-MM'),
    userCount: 0,
    userInfo: {
      ageType: 0,
      ageMin: null,
      ageMax: null,
      genderType: 2,
      genderReason: '',
    },
    name: '',
    keywords: [],
    thumbnail: null,
    imagePlans: [{ file: null, value: '' }, { file: null, value: '' }, { file: null, value: '' }],
    imageSpecial: [{ file: null, value: '' }, { file: null, value: '' }, { file: null, value: '' }],
    imageWithPeople: [{ file: null, value: '' }, { file: null, value: '' }, { file: null, value: '' }],
    host: {
      name: '',
      image: null,
      imageHostInfo: [{ file: null, value: '' }, { file: null, value: '' }, { file: null, value: '' }],
    },
    cities: [],
    schedules: [],
    lodgings: [],
  }
};

function project(state: ProjectState = initialState, action: ProjectAction): ProjectState {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case UPDATE_PROJECT:
      return { ...state, project: action.payload }
    case SET_SCHEDULE_STEP:
      return { ...state, scheduleStep: action.payload };
    default:
      return state;
  }
}

export default project;
