import React from 'react';
import VTempSaveModal from './vac/v_temp_save_modal';

export type TempSaveModalProps = {
  onClose: () => void,
  onSave: () => void,
};

export type VTempSaveModalProps = {
  onClickClose: () => void,
  onClickSave: () => void,
};

const TempSaveModal = ({ onClose, onSave }: TempSaveModalProps) => {
  const vTempSaveModalProps: VTempSaveModalProps = {
    onClickClose: onClose,
    onClickSave: onSave,
  };

  return (
    <>
      <VTempSaveModal {...vTempSaveModalProps} />
    </>
  )
};

export default TempSaveModal;
