import React from 'react';
import styles from './v_start_at_calendar_date.module.css';
import calendar from '../../../static/images/calendar.svg';
import { VStartAtCalendarDateProps } from '../start_at_date';

import 'react-datepicker/dist/react-datepicker.css';

const VStartAtCalendarDate = ({ ref, props }: VStartAtCalendarDateProps) => {
  return (
    <div className={styles.container} onClick={props.onClick} ref={ref}>
      <p className={styles.text}>{props.value}</p>
      <div className={styles.bar} />
      <img src={calendar} alt={'date'} className={styles.icon} />
    </div>
  )
};

export default VStartAtCalendarDate;

