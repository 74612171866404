import React from 'react';
import styles from './v_input_gender.module.css';
import { VInputGenderProps } from '../input_gender';
import classNames from 'classnames';

const VInputGender = ({ reason, error, onChangeReason }: VInputGenderProps) => {
  return (
    <div className={styles.container}>
      <textarea className={classNames(styles.textarea, error ? styles.error : '')} value={reason}
                onChange={onChangeReason}
                placeholder={'남자만 또는 여자만 참가 가능한 이유를 알려주세요.'} />
    </div>
  )
};

export default VInputGender;
