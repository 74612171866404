import React from 'react';
import { SelectProps, styles } from './select';
import Select from 'react-select';

const SelectCity = ({ selectOption, selectOptions, placeholder, onChangeOption }: SelectProps) => {
  return <Select defaultValue={selectOption} options={selectOptions}
                 styles={styles(160)} placeholder={placeholder} onChange={onChangeOption} />;
};

export default SelectCity;
