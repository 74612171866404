import React from 'react';
import VDetailContentContainer from './vac/v_detail_content_container';
import { RadioContainerProps } from './radio_container';
import { ProjectSchedule } from '../models/project';
import { InputProps } from './projects/Input';

export type DetailContainerProps = {
  index: number,
  schedules: ProjectSchedule[],
  onUpdateSchedule: (index: number, schedule: ProjectSchedule) => void,
};

export type VDetailContentContainerProps = {
  mobilityInputProps: InputProps,
  mobilityButtons: RadioContainerProps,
  schedule: ProjectSchedule,
  onChangeValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
};

const DetailContentContainer = ({ index, schedules, onUpdateSchedule }: DetailContainerProps) => {

  const inputProps: InputProps = {
    value: schedules[index].mobility ? schedules[index].mobility as string : '',
    placeholder: '원하는 이동수단과 필요한 이유를 작성해주세요. 예) 전용버스 / 근교도시 000을 가기 위해서 필요합니다.',
    onChangeValue: (value: string) => onUpdateSchedule(index, { ...schedules[index], mobility: value }),
  };

  const vDetailContentContainer: VDetailContentContainerProps = {
    mobilityInputProps: inputProps,
    mobilityButtons: {
      style: 'button',
      value: schedules[index].typeMobility,
      list: ['네, 필요합니다.', '아니요, 필요없습니다.'],
      onClickItem: (i) => onUpdateSchedule(index, { ...schedules[index], typeMobility: i }),
      alert: null
    },
    schedule: schedules[index],
    onChangeValue: (e) => onUpdateSchedule(index, { ...schedules[index], value: e.target.value }),
  };

  return (
    <>
      <VDetailContentContainer {...vDetailContentContainer} />
    </>
  )
};

export default DetailContentContainer;
