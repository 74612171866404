import React from 'react';
import styles from './v_input.module.css';
import { VInputProps } from '../Input';

const VInput = ({ value, placeholder, onChangeValue }: VInputProps) => {
  return (
    <input className={styles.input} type={'text'} value={value} placeholder={placeholder} onChange={onChangeValue} />
  )
};

export default VInput;
