import React from 'react';
import VButtonsContainer from './vac/v_buttons_container';
import { ButtonProps } from './button';
import AlertContainer from './projects/containers/alert_container';

export type ButtonsContainerProps = {
  buttonsProps: ButtonProps[],
  error: string
};

export type VButtonsContainerProps = {
  buttonsProps: ButtonProps[],
};

const ButtonsContainer = ({ buttonsProps, error }: ButtonsContainerProps) => {
  const vButtonsControllerProps: VButtonsContainerProps = {
    buttonsProps: buttonsProps
  };

  return (
    <>
      <VButtonsContainer {...vButtonsControllerProps} />
      {
        error && <AlertContainer alert={''} error={error} />
      }
    </>
  )
};

export default ButtonsContainer;
