import React from 'react';
import classNames from 'classnames';
import styles from './v_project_save_modal.module.css';
import check from '../../../static/images/check_black.svg';
import { VProjectSaveModalProps } from '../project_save_modal';

const VProjectSaveModal = ({ onClickClose, onClickSave }: VProjectSaveModalProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.check_container}>
        <img src={check} alt={'check'} className={styles.check_icon} />
      </div>
      <p className={styles.title}>최종저장이 완료되었습니다.</p>
      <p className={styles.content}>
        아래 [프로그램 신청하기]버튼을 누르면
        <br />
        기획한 여행이 ‘워너고'로 전송되어 1)<u>검토</u>가 시작됩니다.
        <br />
        2)<u>검토완료</u> 후 3)<u>오퍼레이터</u>가 배정됩니다.
        <br />
        오퍼레이터와 4)<u>최종수정</u> 이후 프로그램이 5)<u>프리오픈</u> 됩니다.
        <br />
        덕분에 세상이 더 버라이어티해졌어요👍
      </p>
      <div className={styles.buttons_container}>
        <button onClick={onClickSave} className={classNames(styles.button, styles.dark)}>프로그램 신청하기</button>
        <button onClick={onClickClose} className={classNames(styles.button, styles.white)}>아직 수정할게 남았어요</button>
      </div>
    </div>
  )
};

export default VProjectSaveModal;
