import React, { useState } from 'react';
import VInput from './vac/v_input';
import AlertContainer from './containers/alert_container';

export type InputProps = {
  value: string,
  placeholder: string,
  alert?: string,
  onChangeValue: (value: string) => void,
};

export type VInputProps = {
  value: string,
  placeholder: string,
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const Input = ({ value, placeholder, alert = undefined, onChangeValue }: InputProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(value ? undefined : '값이 비어있습니다.');

  const vInputProps: VInputProps = {
    value: value,
    placeholder: placeholder,
    onChangeValue: (event) => {
      if (event.target.value === '') {
        setErrorMessage('값이 비어있습니다.');
      } else {
        setErrorMessage(undefined);
      }
      onChangeValue(event.target.value);
    }
  };

  return (
    <>
      <VInput {...vInputProps} />
      {
        alert && !errorMessage && <AlertContainer alert={alert} />
      }
      {
        errorMessage && <AlertContainer alert={alert ? alert : ''} error={errorMessage} />
      }
    </>
  )
};

export default Input;
