import React from 'react';
import VScheduleContainers from './vac/v_schedules_container';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/modules';
import { setScheduleStep } from '../../../redux/modules/project';

export type VScheduleContainersProps = {
  tabs: string[],
  tabIndex: number,
  onChangeTab: (tab: number) => void
};

const SchedulesContainer = () => {
  const dispatch = useDispatch();
  const tabIndex = useSelector((state: RootState) => state.project.scheduleStep);
  const project = useSelector((state: RootState) => state.project.project);

  const tabs = ['1.전체일정', '2.세부일정', '3.숙소정보'];

  const vScheduleContainersProps: VScheduleContainersProps = {
    tabs: tabs,
    tabIndex: tabIndex,
    onChangeTab: (tab) => {
      if (project.cities.length > 0) {
        dispatch(setScheduleStep(tab));
      }
    }
  };

  return (
    <>
      <VScheduleContainers {...vScheduleContainersProps} />
    </>
  )
};

export default SchedulesContainer;
