import React, { useState } from 'react';
import VDetailSchedule from './vac/v_detail_schedule';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSchedule } from '../../models/project';
import { RootState } from '../../redux/modules';
import { updateProject } from '../../redux/modules/project';

export type VDetailScheduleProps = {
  index: number,
  setIndex: (index: number) => void,
  schedules: ProjectSchedule[],
  values: string[],
  onUpdateSchedule: (index: number, schedule: ProjectSchedule) => void,
  onDeleteSchedule: (index: number) => void,
};

const DetailSchedule = () => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.project);

  const [index, setIndex] = useState(0);

  const updateSchedules = (schedules: ProjectSchedule[]) => dispatch(updateProject({
    ...project,
    schedules: schedules,
  }));

  const vDetailScheduleProps: VDetailScheduleProps = {
    index: index,
    setIndex: setIndex,
    schedules: project.schedules,
    values: new Array<string>(project.schedules.length).fill('').map((_, i) => `${i + 1}일째`),
    onUpdateSchedule: (index, schedule) => {
      let temp = Object.assign(new Array<ProjectSchedule>(project.schedules.length), project.schedules);
      temp[index] = schedule;
      updateSchedules(temp);
    },
    onDeleteSchedule: (index) => updateSchedules(project.schedules.filter((_, i) => i !== index))
  };

  return (
    <>
      <VDetailSchedule {...vDetailScheduleProps} />
    </>
  )
};

export default DetailSchedule;
