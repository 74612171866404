import React, { useState } from 'react';
import VLodgingSchedule from './vac/v_lodging_schedule';
import { ProjectLodging } from '../../models/project';
import { useDispatch, useSelector } from 'react-redux';
import { updateProject } from '../../redux/modules/project';
import { RootState } from '../../redux/modules';

export type VLodgingScheduleProps = {
  index: number,
  setIndex: (index: number) => void,
  lodgings: ProjectLodging[],
  values: string[],
  onUpdateLodging: (index: number, lodging: ProjectLodging) => void,
  onDeleteLodging: (index: number) => void,
};

const LodgingSchedule = () => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.project);

  const updateLodgings = (lodgings: ProjectLodging[]) => dispatch(updateProject({
    ...project,
    lodgings: lodgings,
  }));

  const [index, setIndex] = useState(0);

  const vLodgingLodgingProps: VLodgingScheduleProps = {
    index: index,
    setIndex: setIndex,
    lodgings: project.lodgings,
    values: project.lodgings.map(lodging => `${lodging.city.name} ${lodging.city.days}박`),
    onUpdateLodging: (index, lodging) => {
      let temp = Object.assign(new Array<ProjectLodging>(project.lodgings.length), project.lodgings);
      temp[index] = lodging;
      updateLodgings(temp);
    },
    onDeleteLodging: (index) => updateLodgings(project.lodgings.filter((_, i) => i !== index))
  };

  return (
    <>
      <VLodgingSchedule {...vLodgingLodgingProps} />
    </>
  )
};

export default LodgingSchedule;
