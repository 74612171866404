import React, { useState } from 'react';
import VCarouselImageTexts from './vac/v_carousel_image_texts';
import { ProjectImageText } from '../models/project';

export type CarouselImageTextsProps = {
  title: string,
  itemIndex: number,
  setItemIndex: (index: number) => void,
  imageTexts: ProjectImageText[]
};

export type VCarouselImageTextsProps = {
  title: string,
  itemIndex: number,
  onClickItem: (index: number) => void,
  imageTexts: ProjectImageText[],
};

const CarouselImageTexts = ({ title, itemIndex, setItemIndex, imageTexts }: CarouselImageTextsProps) => {
  const vCarouselImageTextsProps: VCarouselImageTextsProps = {
    title: title,
    itemIndex: itemIndex,
    onClickItem: (index) => {
      if (index > -1 && index < imageTexts.length) {
        setItemIndex(index)
      }
    },
    imageTexts: imageTexts,
  };

  return (
    <>
      <VCarouselImageTexts {...vCarouselImageTextsProps} />
    </>
  )
};

export default CarouselImageTexts;
