import React from 'react';
import VHeader from './vac/v_header';

export type HeaderProps = {
  onClickBack: () => void,
};

export type VHeaderProps = {
  onClickBack: () => void,
};

const Header = ({ onClickBack }: HeaderProps) => {
  const vHeaderProps: VHeaderProps = {
    onClickBack: onClickBack,
  }

  return (
    <>
      <VHeader {...vHeaderProps} />
    </>
  )
};

export default Header;
