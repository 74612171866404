import React from 'react';
import styles from './v_alert_container.module.css';
import info from '../../../../static/images/info.svg';
import { VAlertContainerProps } from '../alert_container';

const VAlertContainer = ({ alert }: VAlertContainerProps) => {
  return (
    <div className={styles.container}>
      <img src={info} alt={'alert'} className={styles.icon} />
      <pre className={styles.text}>{alert}</pre>
    </div>
  )
};

export default VAlertContainer;
