import React from 'react';
import styles from './v_total_schedule.module.css';
import plus from '../../../static/images/plus_white_no_background.svg';
import CityContainer from '../../city_container';
import { VTotalScheduleProps } from '../total_schedule';

const VTotalSchedule = ({ cities, onCreateCity, onUpdateCity, onDeleteCity }: VTotalScheduleProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>도시별 숙박일수</p>
      {
        cities.map((city, index) =>
          <div key={index} className={styles.city_container}>
            <CityContainer item={city} onUpdateCity={(city) => onUpdateCity(index, city)}
                           onDeleteCity={() => onDeleteCity(index)} />
          </div>
        )
      }
      <div className={styles.divider} />
      <div className={styles.result_container}>
        <p className={styles.result_city}>{cities.length}개 도시</p>
        <p className={styles.result_day}>총 {cities.reduce((prev, city) => prev + city.days, 0)}박</p>
      </div>
      <div className={styles.add_schedule_container} onClick={onCreateCity}>
        <img src={plus} alt={'plus'} className={styles.add_schedule_icon} />
        <p className={styles.add_schedule_text}>일정더하기</p>
      </div>
    </div>
  )
};

export default VTotalSchedule;
