import React, { useState } from 'react';
import VPreviewContentsContainer from './vac/v_preview_contents_container';
import { ProjectHost, ProjectImageText } from '../models/project';
import { CarouselImageTextsProps } from './carousel_image_texts';

export type PreviewContentsContainerProps = {
  host: ProjectHost,
  imageTexts: ProjectImageText[][],
};

export type VPreviewContentsContainerProps = {
  tabIndex: number,
  tabs: string[],
  onClickTab: (index: number) => void,
  carouselImageTextsProps: CarouselImageTextsProps,
};

const PreviewContentsContainer = ({ host, imageTexts }: PreviewContentsContainerProps) => {
  const titles = ['왜? 어떻게? 만들어졌냐면요..?', '🥳이런 점이 특별해요', '이런 분들에게 추천해요.', `${host.name === '' ? 'OOO' : host.name}님을 소개 합니다.`];
  const [tabIndex, setTabIndex] = useState(0);
  const [itemIndex, setItemIndex] = useState(0);

  const vPreviewContentsContainerProps: VPreviewContentsContainerProps = {
    tabIndex: tabIndex,
    tabs: ['기획의도', '특장점', '이런분들오세요', '호스트'],
    onClickTab: index => {
      setTabIndex(index);
      setItemIndex(0);
    },
    carouselImageTextsProps: {
      title: titles[tabIndex],
      itemIndex: itemIndex,
      setItemIndex: setItemIndex,
      imageTexts: imageTexts[tabIndex],
    }
  };

  return (
    <>
      <VPreviewContentsContainer {...vPreviewContentsContainerProps} />
    </>
  )
};

export default PreviewContentsContainer;
