import React, { useState } from 'react';
import VCityContainer from './vac/v_city_container';
import { City } from '../contexts';
import { selectOption, SelectProps } from './selects/select';
import { ProjectCity } from '../models/project';

export type CityContainerProps = {
  item: ProjectCity,
  onUpdateCity: (city: ProjectCity) => void,
  onDeleteCity: () => void
};

export type VCityContainerProps = {
  city: ProjectCity
  options: SelectProps,
  isHoverMinus: boolean,
  setHoverMinus: (hover: boolean) => void,
  isHoverPlus: boolean,
  setHoverPlus: (hover: boolean) => void,
  onIncrease: () => void;
  onDecrease: () => void;
  onDelete: () => void,
};

const CityContainer = ({ item, onUpdateCity, onDeleteCity }: CityContainerProps) => {
  const options: selectOption[] = [
    {
      label: 'Paris. France',
      value: 'Paris,France,Europe',
    },
    {
      label: 'Marseille, France',
      value: 'Marseille,France,Europe',
    },
    {
      label: 'Berlin, Germany',
      value: 'Berlin,Germany,Europe',
    },
    {
      label: 'Tokyo',
      value: 'Tokyo,Japan,Asia',
    }
  ];

  const [hoverMinus, setHoverMinus] = useState(false);
  const [hoverPlus, setHoverPlus] = useState(false);

  const updateCity = (city: ProjectCity) => {
    onUpdateCity(city);
  };

  const vCityContainerProps: VCityContainerProps = {
    city: item,
    isHoverMinus: hoverMinus,
    setHoverMinus: (hover) => setHoverMinus(hover),
    isHoverPlus: hoverPlus,
    setHoverPlus: (hover) => setHoverPlus(hover),
    onIncrease: () => updateCity({ ...item, days: item.days + 1 }),
    onDecrease: () => updateCity({ ...item, days: item.days === 0 ? 0 : item.days - 1 }),
    options: {
      selectOption: options.filter(option => option.value === item.name),
      selectOptions: options,
      placeholder: '도시명을 검색하세요',
      onChangeOption: (newValue) => {
        const city = newValue as selectOption;
        updateCity({
          name: city.value.split(',')[0],
          days: 0,
          country: city.value.split(',')[1],
          area: city.value.split(',')[2]
        });
      },
    },
    onDelete: () => onDeleteCity()
  };

  return (
    <>
      <VCityContainer {...vCityContainerProps} />
    </>
  )
};

export default CityContainer;
