import React from 'react';
import TotalSchedule from '../total_schedule';
import DetailSchedule from '../detail_schedule';
import LodgingSchedule from '../lodging_schedule';

const ScheduleContainers = ({ tabIndex }: { tabIndex: number }) => {
  switch (tabIndex) {
    case 0:
      return <TotalSchedule />;
    case 1:
      return <DetailSchedule />
    case 2:
      return <LodgingSchedule />;
    default:
      return null;
  }
};

export default ScheduleContainers;
