import React from 'react';
import ContentContainer, { ContentProps } from '../../../components/content_container';
import Input, { InputProps } from '../../../components/projects/Input';
import ImageUpload, { ImageUploadProps } from '../../../components/projects/image_upload';
import ImageTextUploadContainer, {
  ImageTextUploadContainerProps
} from '../../../components/projects/containers/image_text_upload_container';
import SelectKeywordContainer, { SelectKeywordContainerProps } from '../../../components/select_keyword_container';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/modules';
import { updateProject } from '../../../redux/modules/project';

const DescriptionContent = () => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project.project);

  const inputProps: InputProps = {
    value: project.name,
    placeholder: '여행의 컨셉이 잘 드러나는 제목을 작성해주세요 :)',
    onChangeValue: (value) => dispatch(updateProject({
      ...project,
      name: value
    }))
  };

  const selectKeywordContainerProps: SelectKeywordContainerProps = {
    values: project.keywords,
    placeholder: '이번 여행을 가장 잘 표현할 수 있는 키워드는 무엇인가요?',
    alert: '키워드 작성후 엔터키를 누르시면 자동으로 키워드가 입력 됩니다. 키워드는 최소 3개에서 6개를 입력해주세요',
    onChangeOptions: values => dispatch(updateProject({
      ...project,
      keywords: values,
    }))
  };

  const imageUploadProps: ImageUploadProps = {
    file: project.thumbnail,
    setFile: (file: File | null) => dispatch(updateProject({
      ...project,
      thumbnail: file
    })),
    alert: '3MB 이하의 JPEG, PNG 파일'
  };

  const imagePlansContainerProps: ImageTextUploadContainerProps = {
    imageTexts: project.imagePlans.map(plan => ({ file: plan.file, value: plan.value, placeholder: '글을 작성해 주세요' })),
    setImageTexts: (imageTexts) => dispatch(updateProject({
      ...project,
      imagePlans: imageTexts.map(imageText => ({ file: imageText.file, value: imageText.value }))
    })),
    alert: '이 여행을 통해 사람들이 무엇을 느끼고 남기고 오길 바라나요?\n이 여행을 왜 만들고 왜 사람들과 공유하고 싶나요?'
  };

  const imageSpecialContainerProps: ImageTextUploadContainerProps = {
    imageTexts: project.imageSpecial.map(plan => ({ file: plan.file, value: plan.value, placeholder: '글을 작성해 주세요' })),
    setImageTexts: (imageTexts) => dispatch(updateProject({
      ...project,
      imageSpecial: imageTexts.map(imageText => ({ file: imageText.file, value: imageText.value }))
    })),
    alert: '이 여행이 다른 여행과 어떤 점이 다른가요? 특별한 매력을 어필해 주세요!'
  };

  const imageWithPeopleContainerProps: ImageTextUploadContainerProps = {
    imageTexts: project.imageWithPeople.map(plan => ({
      file: plan.file,
      value: plan.value,
      placeholder: '글을 작성해 주세요'
    })),
    setImageTexts: (imageTexts) => dispatch(updateProject({
      ...project,
      imageWithPeople: imageTexts.map(imageText => ({ file: imageText.file, value: imageText.value }))
    })),
    alert: '어떤 사람들과 함께 하면 좋을까요? 그들이 ‘이건 내 얘기잖아!’ 라고 말한다면 성공이에요!'
  };

  const contentsProps: ContentProps[] = [
    {
      title: '여행설명 1',
      description: '준비하고 계신 여행을 한눈에 알 수 있도록 제목과 이미지를 등록해 주세요.',
      columns: [
        {
          title: '여행 소개 제목',
          component: <Input {...inputProps} />,
        },
        {
          title: '여행 소개 키워드',
          component: <SelectKeywordContainer {...selectKeywordContainerProps} />,
        },
        {
          title: '대표사진(썸네일)',
          component: <ImageUpload {...imageUploadProps} />
        }
      ]
    },
    {
      title: '여행설명 2',
      description: '어떤 여행을 기획하고 있는지 조금 더 자세히 알려주세요:)',
      columns: [
        {
          title: '프로그램 기획 의도는?',
          component: <ImageTextUploadContainer {...imagePlansContainerProps} />,
        },
        {
          title: '이런 점이 특별해요',
          component: <ImageTextUploadContainer {...imageSpecialContainerProps} />,
        },
        {
          title: '이런 사람이 함께하면 좋아요',
          component: <ImageTextUploadContainer {...imageWithPeopleContainerProps} />,
        },
      ]
    }
  ];

  return (
    <>
      {
        contentsProps.map((contentProps, i) => <ContentContainer key={i} {...contentProps} />)
      }
    </>
  )
};

export default DescriptionContent;
