import React from 'react';
import VImageTextUploadsContainer from '../../vac/v_image_text_uploads_container';
import AlertContainer from './alert_container';
import { ImageUploadProps } from '../image_upload';

export type ImageText = {
  file: File | null,
  value: string,
  placeholder: string,
};

export type ImageTextUploadContainerProps = {
  imageTexts: ImageText[],
  setImageTexts: (imageTexts: ImageText[]) => void,
  alert?: string
};

export type VImageTextUploadContainerProps = {
  value: string,
  onChangeValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  placeholder: string,
  imageUploadProps: ImageUploadProps,
};

export type VImageTextUploadsContainerProps = {
  imageTextUploadsContainerProps: VImageTextUploadContainerProps[]
};

const ImageTextUploadContainer = ({ imageTexts, setImageTexts, alert }: ImageTextUploadContainerProps) => {
  const updateImageTexts = (index: number, newItem: ImageText) => setImageTexts(imageTexts.map((item, i) => i === index ? newItem : item));

  const imageTextUploadsContainerProps: VImageTextUploadContainerProps[] = imageTexts.map((imageText, index) => ({
    value: imageTexts[index].value,
    onChangeValue: (e) => updateImageTexts(index, { ...imageTexts[index], value: e.target.value }),
    placeholder: imageTexts[index].placeholder,
    imageUploadProps: {
      file: imageTexts[index].file,
      setFile: (file) => updateImageTexts(index, { ...imageTexts[index], file: file }),
      alert: undefined
    },
  }));

  const vImageTextUploadsContainerProps: VImageTextUploadsContainerProps = {
    imageTextUploadsContainerProps: imageTextUploadsContainerProps
  };

  return (
    <>
      <VImageTextUploadsContainer {...vImageTextUploadsContainerProps} />
      {
        alert && <AlertContainer alert={alert} />
      }
    </>
  )
};

export default ImageTextUploadContainer;
