import React from 'react';
import { SelectProps, styles } from './select';
import VOption, { VOptionProps } from './vac/v_option';
import Select from 'react-select';

const SelectDate = ({ selectOption, selectOptions, placeholder, onChangeOption }: SelectProps) => {
  return (
    <Select defaultValue={selectOption} options={selectOptions}
            styles={styles(160)} placeholder={placeholder} isSearchable={false} onChange={onChangeOption}
            components={{
              Option: (vOptionProps: VOptionProps) => <VOption {...vOptionProps} />
            }} />
  )
};

export default SelectDate;
