import React from 'react';
import styles from './v_preview_contents_container.module.css';
import classNames from 'classnames';
import CarouselImageTexts from '../carousel_image_texts';
import { VPreviewContentsContainerProps } from '../preview_contents_container';

const VPreviewContentsContainer = ({ tabIndex, tabs, onClickTab, carouselImageTextsProps }: VPreviewContentsContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.tabs_container}>
        {
          tabs.map((tab, i) =>
            <div key={i} onClick={() => onClickTab(i)}
                 className={classNames(styles.tab, i === tabIndex && styles.active_tab)}>
              {tab}
            </div>
          )
        }
      </div>
      <CarouselImageTexts {...carouselImageTextsProps} />
    </div>
  )
};

export default VPreviewContentsContainer;
