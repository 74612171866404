import React from 'react';
import styles from './v_preview_project.module.css';
import bookmark from '../../static/images/bookmark.svg';
import alarm from '../../static/images/alarm.svg';
import AlertContainer from '../projects/containers/alert_container';
import PreviewContentsContainer from '../preview_contents_container';
import { VPreviewProjectProps } from '../preview_project';

const VPreviewProject = ({ tags, name, host, keywords, deadline, imageTexts }: VPreviewProjectProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.info_container}>
        <div className={styles.info_header_container}>
          <div className={styles.tags_container}>
            {
              tags.map((tag, i) => <p key={i} className={styles.tag}>{tag}</p>)
            }
          </div>
          <p className={styles.new_icon}>NEW</p>
        </div>
        <p className={styles.title}>{name}</p>
        <div className={styles.host_container}>
          {
            host.image ? <img src={URL.createObjectURL(host.image)} alt={'image'} className={styles.host_image} /> :
              <div className={styles.no_host_image} />
          }
          <p className={styles.host_name}>{host.name}</p>
        </div>
        <div className={styles.keywords_container}>
          {
            keywords.map((keyword, i) => <p key={i} className={styles.keyword}>#{keyword}</p>)
          }
        </div>
        <p className={styles.deadline}>{deadline}</p>
      </div>
      <div className={styles.content_container}>
        <PreviewContentsContainer host={host} imageTexts={imageTexts} />
      </div>
      <div className={styles.alarm_container}>
        <div className={styles.buttons_container}>
          <div className={styles.bookmark_container}>
            <img src={bookmark} alt={'bookmark'} className={styles.bookmark_icon} />
          </div>
          <div className={styles.alarm_button_container}>
            <img src={alarm} alt={'alarm'} className={styles.alarm_icon} />
            <p className={styles.alarm_text}>알람신청</p>
          </div>
        </div>
        <AlertContainer alert={'알람 신청수는 추후 집계되며, 일정 신청수가 넘어야 프로그램\n오픈이 확정됩니다. '} />
      </div>
    </div>
  )
};

export default VPreviewProject;
