import { applyMiddleware, compose, createStore } from 'redux';

import rootReducer from './modules';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const store = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = applyMiddleware();
  return createStore(rootReducer, composeEnhancers(middleware));
};

export default store;
