import React from 'react';
import styles from './v_detail_schedule.module.css';
import HorizontalTagsContainer from '../containers/horizontal_tags_container';
import DetailContentContainer from '../../detail_content_container';
import { VDetailScheduleProps } from '../detail_schedule';

const VDetailSchedule = ({ index, setIndex, schedules, values, onUpdateSchedule }: VDetailScheduleProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>일자별 일정을 입력해주세요</p>
      <div className={styles.tags_container}>
        <HorizontalTagsContainer index={index} setIndex={setIndex} values={values} />
      </div>
      <div className={styles.content_container}>
        <DetailContentContainer index={index} schedules={schedules} onUpdateSchedule={onUpdateSchedule} />
      </div>
    </div>
  )
};

export default VDetailSchedule;
