import React from 'react';
import styles from './v_header.module.css';
import { VHeaderProps } from '../header';

const VHeader = ({ onClickBack }: VHeaderProps) => {
  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={onClickBack}>나가기</button>
    </div>
  )
};

export default VHeader;
