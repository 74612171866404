import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { routes, projectRoutes } from './data';
import AuthRoute from './auth_route';
import Login from '../pages/login';

const ProjectRoutes = () => {
  return (
    <>
      <Switch>
        {
          projectRoutes.map(route => <AuthRoute key={route.path} route={route} />)
        }
      </Switch>
    </>
  )
};

const MainRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/login'} component={Login} />
        {
          routes.map(route => <AuthRoute key={route.path} route={route} />)
        }
        <Redirect path="*" to="/" />
      </Switch>
    </>
  )
};

const Routers = () => {
  return (
    <Router>
      <Switch>
        <Route path={'/projects'} component={ProjectRoutes} />
        <Route path={'/'} component={MainRoutes} />
      </Switch>
    </Router>
  )
}

export default Routers;
