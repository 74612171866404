import React, { useEffect, useState } from 'react';
import AlertContainer from './projects/containers/alert_container';
import SelectKeyword from './selects/select_keyword';
import { MultiValue } from 'react-select';
import { selectOption, SelectProps } from './selects/select';

export type SelectKeywordContainerProps = {
  values: string[],
  placeholder: string,
  alert: string,
  onChangeOptions: (values: string[]) => void,
};

const SelectKeywordContainer = ({ values, alert, placeholder, onChangeOptions }: SelectKeywordContainerProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const selectKeywordProps: SelectProps = {
    selectOption: values.map(v => ({ label: v, value: v })),
    selectOptions: [],
    placeholder: placeholder,
    onChangeOption: (newValue) => {
      const options = newValue as MultiValue<selectOption>;
      onChangeOptions(options.slice(0, 6).map(option => option.value));
    },
  };

  useEffect(() => {
    if (values.length > 0 && values.length < 3) {
      setErrorMessage('키워드는 최소 3개 입력입니다.');
    } else if (values.length > 6) {
      setErrorMessage('키워드는 최대 6개 입력입니다.');
    } else {
      setErrorMessage(undefined);
    }
  }, [values]);

  return (
    <>
      <SelectKeyword {...selectKeywordProps} />
      {
        <AlertContainer alert={alert} error={errorMessage} />
      }
    </>
  )
};

export default SelectKeywordContainer;
