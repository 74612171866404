import React from 'react';
import styles from './v_buttons_container.module.css';
import Button from '../button';
import { VButtonsContainerProps } from '../buttons_container';

const VButtonsContainer = ({ buttonsProps }: VButtonsContainerProps) => {
  return (
    <div className={styles.container}>
      {
        buttonsProps.map((buttonProps, index) => <Button key={index} {...buttonProps} />)
      }
    </div>
  )
};

export default VButtonsContainer;
