import React from 'react';
import ReactModal from 'react-modal';
import styles from './modal.module.css';
import close from '../../static/images/close.svg';

const modalStyles = {
  overlay: {
    zIndex: 4,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    border: '1px solid #DEE2E6',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px'
  },
};

export type ModalProps = {
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,
  component: JSX.Element,
};

const Modal = ({ isOpen, setIsOpen, component }: ModalProps) => {
  return (
    <ReactModal isOpen={isOpen} style={modalStyles}>
      <div className={styles.container}>
        <div className={styles.close_button} onClick={() => setIsOpen(false)}>
          <img src={close} alt={'close'} className={styles.close_icon} />
        </div>
        {
          component
        }
      </div>
    </ReactModal>
  )
};

export default Modal;
