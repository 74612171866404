import React from 'react';
import styles from './v_image_upload.module.css';
import icon from '../../../static/images/upload.svg';
import { VImageUploadProps } from '../image_upload';

const VImageUpload = ({ onChangeFile }: VImageUploadProps) => {
  return (
    <div className={styles.container}>
      <input className={styles.file_input} type={'file'} accept="impge/png,image/jpeg" onChange={onChangeFile} />
      <img src={icon} alt={'upload'} className={styles.icon} />
      <p className={styles.text}>사진 업로드</p>
    </div>
  )
};

export default VImageUpload;
