import React from 'react';
import VLodgingContentContainer from './vac/v_lodging_content_container';
import { RadioContainerProps } from './radio_container';
import { ProjectLodging } from '../models/project';
import { InputProps } from './projects/Input';

export type LodgingContainerProps = {
  index: number,
  lodgings: ProjectLodging[],
  onUpdateLodging: (index: number, lodging: ProjectLodging) => void,
};

export type VLodgingContentContainerProps = {
  lodgingTags: RadioContainerProps,
  lodging: ProjectLodging,
  nameInputProps: InputProps,
  addressInputProps: InputProps,
  homepageInputProps: InputProps,
};

const LodgingContentContainer = ({ index, lodgings, onUpdateLodging }: LodgingContainerProps) => {
  const nameInputProps: InputProps = {
    value: lodgings[index].name,
    placeholder: '구글로 검색 가능한 정확한 숙소 이름을 영문명으로 작성해 주세요.',
    alert: '여러 지점이 있는 경우 지역명이나 지점명까지 입력해 주세요.',
    onChangeValue: (value: string) => onUpdateLodging(index, { ...lodgings[index], name: value }),
  };

  const addressInputProps: InputProps = {
    value: lodgings[index].address,
    placeholder: '구글 검색시 나오는 정확한 주소를 복사하여 붙여 넣어 주세요.',
    alert: '영문으로 입력 해주세요.',
    onChangeValue: (value: string) => onUpdateLodging(index, { ...lodgings[index], address: value }),
  };

  const homepageInputProps: InputProps = {
    value: lodgings[index].homepage,
    placeholder: '추천하고 싶은 숙소 정보를 볼 수 있는 홈페이지 url 입력',
    onChangeValue: (value: string) => onUpdateLodging(index, { ...lodgings[index], homepage: value }),
  };

  const vLodgingContentContainer: VLodgingContentContainerProps = {
    lodgingTags: {
      style: 'tags',
      value: lodgings[index].type,
      list: ['호텔', '호스텔', '에어비엔비', '한인민박', '캠핑장', '산장', '백패킹', '이동수단숙박', '캠핑카&차박', '추후논의', '상관없음', '추천하고 싶은 숙소가 있어요'],
      onClickItem: (item) => onUpdateLodging(index, { ...lodgings[index], type: item }),
      alert: null
    },
    lodging: lodgings[index],
    nameInputProps: nameInputProps,
    addressInputProps: addressInputProps,
    homepageInputProps: homepageInputProps,
  };


  return (
    <>
      <VLodgingContentContainer {...vLodgingContentContainer} />
    </>
  )
};

export default LodgingContentContainer;
