import React from 'react';
import classNames from 'classnames';
import styles from './v_side_bar.module.css';
import { VSideBarProps } from '../sidebar';

const VSideBar = ({ list, each }: VSideBarProps) => {
  return (
    <div className={styles.container}>
      {
        list.map((item, index) => {
          const { onClickItem, isActive } = each(item, index);
          return (
            <div key={index} onClick={onClickItem}
                 className={classNames(styles.item, isActive() ? styles.item_active : '')}>{index + 1}. {item}</div>
          )
        })
      }
    </div>
  )
};

export default VSideBar;
