import React from 'react';
import VAlertContainer from './vac/v_alert_container';
import VAlertErrorContainer from './vac/v_alert_error_container';

export type VAlertContainerProps = {
  alert: string,
  error: string | undefined
}

const AlertContainer = ({ alert, error = undefined }: { alert: string, error?: string }) => {
  const vAlertContainerProps: VAlertContainerProps = {
    alert: alert,
    error: error
  }

  return (
    <>
      {
        error ? <VAlertErrorContainer {...vAlertContainerProps} /> : <VAlertContainer {...vAlertContainerProps} />
      }
    </>
  )
};

export default AlertContainer;
