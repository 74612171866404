import React from 'react';
import styles from './v_detail_content_container.module.css';
import RadioContainer from '../radio_container';
import Input from '../projects/Input';
import { VDetailContentContainerProps } from '../detail_content_container';

const VDetailContentContainer = ({
                                   mobilityInputProps, mobilityButtons,
                                   schedule, onChangeValue
                                 }: VDetailContentContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.current_container}>
        <p className={styles.current_title}>오늘의 여행지</p>
        <p className={styles.current_city}>{schedule.city_name}</p>
      </div>
      <textarea className={styles.detail_text} value={schedule.value} onChange={onChangeValue}
                placeholder={'해당 일자의 여행 계획을 자세히 작성해 주세요.\n' +
                  '\n' +
                  '- 해당일에 꼭 해야 하는 활동이 있나요? 무엇인가요? \n' +
                  '- 자유일정이라면? ‘자유일정’으로 작성. \n' +
                  '- 아직 정해진 계획인 없다면? ‘미정\'으로 작성\n' +
                  '- 참가자들에게 공개되지 않습니다. 내부 검토를 위한 사항이니 자유롭게 써도 좋아요:) '} />
      <div>
        <p className={styles.input_title}>해당 일자에 별도의 이동 수단이 필요한가요?</p>
        <RadioContainer {...mobilityButtons} />
        {
          schedule.typeMobility === 0 &&
          <div className={styles.input_container}>
            <Input {...mobilityInputProps} />
          </div>
        }
      </div>
    </div>
  )
};

export default VDetailContentContainer;
