import React from 'react';
import ContentContainer, { ContentProps } from '../../../components/content_container';
import RadioContainer, { RadioContainerProps } from '../../../components/radio_container';
import StartAtDate, { StartAtDateProps } from '../../../components/projects/start_at_date';
import InputAge, { InputAgeProps } from '../../../components/projects/input_age';
import InputGender, { InputGenderProps } from '../../../components/projects/input_gender';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/modules';
import { updateProject } from '../../../redux/modules/project';

const InfoContent = () => {
  const project = useSelector((state: RootState) => state.project.project);
  const dispatch = useDispatch();

  const onSelectSeason = (index: number) => {
    dispatch(updateProject({
      ...project,
      season: index
    }));
  };

  const onSelectStartAtType = (index: number) => {
    dispatch(updateProject({
      ...project,
      typeGoOff: index
    }));
  };

  const onSelectUserCount = (index: number) => {
    dispatch(updateProject({
      ...project,
      userCount: index
    }));
  };

  const onSelectUserInfoAgeType = (index: number) => {
    dispatch(updateProject({
      ...project,
      userInfo: {
        ...project.userInfo,
        ageType: index,
      }
    }));
  };

  const onSelectUserInfoGenderType = (index: number) => {
    dispatch(updateProject({
      ...project,
      userInfo: {
        ...project.userInfo,
        genderType: index,
      }
    }));
  };

  const seasonTags: RadioContainerProps = {
    style: 'tag',
    value: project.season,
    list: ['봄 3월~5월', '여름 6월~8월', '가을 9월~11월', '겨울 12월~2월'],
    onClickItem: (index) => onSelectSeason(index),
    alert: '여러 팀을 기획 중인 경우는 첫 팀 출발 기준으로 선택해 주세요.'
  };

  const startAtButtons: RadioContainerProps = {
    style: 'button',
    value: project.typeGoOff,
    list: ['유연한 출발 일정', '정확한 출발 일정'],
    onClickItem: (index) => onSelectStartAtType(index),
    alert: '정확한 출발일이 정해지지 않았다면? 유연한 출발 일정을 선택해 주세요.'
  };

  const startAtDateProps: StartAtDateProps = {
    type: project.typeGoOff,
    dateString: project.atGoOff,
    onChangeDate: (date) => dispatch(updateProject({
      ...project,
      atGoOff: date,
    }))
  }

  const userCountTags: RadioContainerProps = {
    style: 'tag',
    value: project.userCount,
    list: ['6인 이하', '6인~12인', '12인~20인', '20인~40인', '40인 이상', '상관없음'],
    onClickItem: (index) => onSelectUserCount(index),
    alert: '한 팀 기준, 참가 예상 인원이 몇 명인가요?'
  };

  const ageButtons: RadioContainerProps = {
    style: 'button',
    value: project.userInfo.ageType,
    list: ['상관없음', '직접입력'],
    onClickItem: (index) => onSelectUserInfoAgeType(index),
    alert: null,
  };

  const inputAgeProps: InputAgeProps = {
    ageMin: project.userInfo.ageMin,
    ageMax: project.userInfo.ageMax,
    alert: '미성년자는 참가대상이 아닙니다. 미성년자 관련 프로그램은 ‘직접입력’ 후 문의해 주세요.',
    onChangeAge: (min, max) => dispatch(updateProject({
      ...project,
      userInfo: {
        ...project.userInfo,
        ageMin: min,
        ageMax: max,
      }
    }))
  };

  const genderTags: RadioContainerProps = {
    style: 'tag',
    value: project.userInfo.genderType,
    list: ['남자만 참가 가능', '여자만 참가 가능', '상관없음'],
    onClickItem: (index) => onSelectUserInfoGenderType(index),
    alert: null,
  };

  const inputGenderProps: InputGenderProps = {
    reason: project.userInfo.genderReason,
    alert: '정확한 출발일이 정해지지 않았다면? 유연한 출발 일정을 선택해 주세요.',
    onChangeReason: (reason) => dispatch(updateProject({
      ...project,
      userInfo: {
        ...project.userInfo,
        genderReason: reason,
      }
    }))
  };

  const contentsProps: ContentProps[] = [
    {
      title: '여행 기본 정보',
      description: '현재 기획중인 여행의 기본 정보를 입력 해주세요.',
      columns: [
        {
          title: '출발 예정 시즌',
          component: <RadioContainer {...seasonTags} />,
        },
        {
          title: '예상하는 여행 출발일',
          component: <RadioContainer {...startAtButtons} />,
          actionComponent: <StartAtDate {...startAtDateProps} />,
        }
      ]
    },
    {
      title: '참가대상',
      description: '최종 확정시 인원은 바뀔 수 있습니다. 편하게 작성해 주세요.',
      columns: [
        {
          title: '참가 인원',
          component: <RadioContainer {...userCountTags} />,
        },
        {
          title: '참가자 나이',
          component: <RadioContainer {...ageButtons} />,
          actionComponent: project.userInfo.ageType === 0 ?
            <></> : <InputAge {...inputAgeProps} />,
        },
        {
          title: '성별',
          component: <RadioContainer {...genderTags} />,
          actionComponent: project.userInfo.genderType !== 2 ?
            <InputGender {...inputGenderProps} /> : <></>,
        },
      ]
    }
  ];

  return (
    <>
      {
        contentsProps.map((contentProps, i) => <ContentContainer key={i} {...contentProps} />)
      }
    </>
  )
};

export default InfoContent;
