import React from 'react';
import classNames from 'classnames';
import styles from './v_carousel_image_texts.module.css';
import leftArrow from '../../static/images/left_arror.svg';
import rightArrow from '../../static/images/right_arrow.svg';
import { VCarouselImageTextsProps } from '../carousel_image_texts';

const VCarouselImageTexts = ({ title, itemIndex, onClickItem, imageTexts }: VCarouselImageTextsProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <div className={styles.images_container}>
        {
          imageTexts[itemIndex].file &&
          <img src={URL.createObjectURL(imageTexts[itemIndex].file)} alt={'image'} className={styles.image} />
        }
        <div onClick={() => onClickItem(itemIndex - 1)} className={classNames(styles.arrow_button, styles.arrow_left)}>
          <img src={leftArrow} alt={'left'} className={styles.arrow_img} />
        </div>
        <div onClick={() => onClickItem(itemIndex + 1)} className={classNames(styles.arrow_button, styles.arrow_right)}>
          <img src={rightArrow} alt={'right'} className={styles.arrow_img} />
        </div>
      </div>
      <div className={styles.dots_container}>
        {
          imageTexts.map((_, index) =>
            <div key={index} onClick={() => onClickItem(index)}
                 className={classNames(styles.dot, index === itemIndex && styles.active_dot)} />)
        }
      </div>
      <div className={styles.value_container}>
        <p className={styles.value}>{imageTexts[itemIndex].value}</p>
      </div>
    </div>
  )
};

export default VCarouselImageTexts;
