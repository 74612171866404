import React from 'react';
import styles from './sub_title.module.css';

type SubTitleProps = {
  title: string
  description: string
}

const SubTitle = ({ title, description }: SubTitleProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
    </div>
  )
};

export default SubTitle;
