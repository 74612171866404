import React from 'react';
import classNames from 'classnames';
import styles from './v_alert_container.module.css';
import info_error from '../../../../static/images/info_error.svg';
import { VAlertContainerProps } from '../alert_container';

const VAlertErrorContainer = ({ error }: VAlertContainerProps) => {
  return (
    <div className={styles.container}>
      <img src={info_error} alt={'alert'} className={styles.icon} />
      <pre className={classNames(styles.text, styles.error_text)}>{error}</pre>
    </div>
  )
};

export default VAlertErrorContainer;
