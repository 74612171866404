import React, { useState } from 'react';
import VInputAge from './vac/v_input_age';
import AlertContainer from './containers/alert_container';

export type InputAgeProps = {
  ageMin: number | null,
  ageMax: number | null,
  alert: string,
  onChangeAge: (min: number | null, max: number | null) => void,
}

export type VInputAgeProps = {
  minAge: string,
  maxAge: string,
  onChangeMinAge: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeMaxAge: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const InputAge = ({ ageMin, ageMax, alert, onChangeAge }: InputAgeProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(ageMin || ageMax ? undefined : '값이 비어있습니다.');
  const [minAge, setMinAge] = useState(ageMin ? String(ageMin) : '');
  const [maxAge, setMaxAge] = useState(ageMax ? String(ageMax) : '');

  const onChange = (min: string, max: string) => onChangeAge(
    Number(min) === 0 ? null : Number(min), Number(max) === 0 ? null : Number(max));

  const vInputAgeProps: VInputAgeProps = {
    minAge: minAge,
    maxAge: maxAge,
    onChangeMinAge: (event) => {
      if (event.target.value === '' || Number(event.target.value)) {
        if (Number(event.target.value) > Number(maxAge)) {
          setErrorMessage('최대 나이가 최소 나이보다 적습니다.');
        } else if (event.target.value === '') {
          setErrorMessage('값이 비어있습니다.');
        } else {
          setErrorMessage(undefined);
        }
        setMinAge(event.target.value);
        onChange(event.target.value, maxAge);
      }
    },
    onChangeMaxAge: (event) => {
      if (event.target.value === '' || Number(event.target.value)) {
        if (Number(event.target.value) < Number(minAge)) {
          setErrorMessage('최대 나이가 최소 나이보다 적습니다.');
        } else if (event.target.value === '') {
          setErrorMessage('값이 비어있습니다.');
        } else {
          setErrorMessage(undefined);
        }
        setMaxAge(event.target.value);
        onChange(minAge, event.target.value);
      }
    },
  };

  return (
    <>
      <VInputAge {...vInputAgeProps} />
      {
        <AlertContainer alert={alert} error={errorMessage} />
      }
    </>
  )
};

export default InputAge;
