import React from 'react';
import VProjectSaveModal from './vac/v_project_save_modal';

export type ProjectSaveModalProps = {
  onClose: () => void,
  onSave: () => void,
};

export type VProjectSaveModalProps = {
  onClickClose: () => void,
  onClickSave: () => void,
};

const ProjectSaveModal = ({ onClose, onSave }: ProjectSaveModalProps) => {
  const vProjectSaveModalProps: VProjectSaveModalProps = {
    onClickClose: onClose,
    onClickSave: onSave
  };

  return (
    <>
      <VProjectSaveModal {...vProjectSaveModalProps} />
    </>
  )
};

export default ProjectSaveModal;
