import React, { useState } from 'react';
import VTagRadioContainer from './vac/v_tag_radio_container';
import AlertContainer from './projects/containers/alert_container';
import VButtonRadioContainer from './vac/v_button_radio_container';

export type RadioContainerProps = {
  style: string,
  value: number,
  list: string[],
  onClickItem: (index: number) => void,
  alert: string | null,
};

export type VRadioContainerProps = {
  list: string[],
  each: (item: string, index: number) => {
    isActive: () => boolean,
    onClickItem: () => void,
  },
};

const RadioContainer = ({ style, value, list, onClickItem, alert }: RadioContainerProps) => {
  const onClick = (index: number) => {
    onClickItem(index);
  };

  const vRadioContainerProps: VRadioContainerProps = {
    list: list,
    each: (item: string, i: number) => ({
      isActive: () => i === value,
      onClickItem: () => onClick(i)
    }),
  };

  return (
    <>
      {
        style === 'button' ?
          <VButtonRadioContainer {...vRadioContainerProps} /> :
          <VTagRadioContainer {...vRadioContainerProps} />
      }
      {
        alert && <AlertContainer alert={alert} />
      }
    </>
  )
};

export default RadioContainer;
